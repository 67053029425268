import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  tableRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.8),
      width: 180,
    },
    '& th': {
      fontWeight: "bold",
      padding: "10px",
      border: "1px solid #80808036",
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: "white",
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)"
    },
    '& td': {
      zIndex: 1,
      border: "1px solid #80808036",
      padding: "3px"
    },
    '& td.rowNum': {
      zIndex: 1,
      border: "1px solid #80808036",
      textAlign: "center"
    }
  },
}));

const INVALID_STATUS = 'INVALID';
const VALID_STATUS = 'ACTIVE';

function EntitlementDisplayComponent({ data }) {
  const classes = useStyles();
  
  return (
    <div>
      <div className="section is-6">
        <div className="columns">
          <div className="column is-offset-1">
            <TextField
              variant="outlined"
              label="Total Records:"
              name="totalRecords"
              value={data.length}
              type="number"
              InputProps={{
                readOnly: true,
              }} />
          </div>
          <div className="column">
            <TextField
              variant="outlined"
              label="Total Valid Records:"
              name="totalValid"
              type="number"
              value={data.filter(d => VALID_STATUS === d.status).length}
              InputProps={{
                readOnly: true,
              }} />
          </div>
          <div className="column">
            <TextField
              variant="outlined"
              label="Total Invalid Records:"
              name="totalInvalid"
              type="number"
              value={data.filter(d => INVALID_STATUS === d.status).length}
              InputProps={{
                readOnly: true,
              }} />
          </div>
        </div>
      </div>

      <div style={{ overflow: "auto", minHeight: "200px", maxHeight: "450px" }}>
        <table className={classes.tableRoot}>
          <thead>
            <tr>
              <th>Row Number</th>
              <th>Client Number</th>
              <th>Service Requested (Sr) Number</th>
              <th>Repair Envelope Number Or Invoice Number</th>
              <th>Store Number (Where Purchased)</th>
              <th>Terminal Number</th>
              <th>Date Of Purchase</th>
              <th>Transaction Number / Plan Number</th>
              <th>Failure Date</th>
              <th>Service Center Receive Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) =>
              <React.Fragment key={row.index}>
                <tr className={INVALID_STATUS === row.status ? 'has-text-danger' : ''}>
                  <td className="rowNum">{row.index}</td>
                  <td>{row.clientNumber}</td>
                  <td>{row.srNumber}</td>
                  <td>{row.repairEnvelopeNumber}</td>
                  <td>{row.storePurchaseNumber}</td>
                  <td>{row.terminalNumber}</td>
                  <td>{row.purchaseDate}</td>
                  <td>{row.transactionNumber}</td>
                  <td>{row.failureDate}</td>
                  <td>{row.scReceiveDate}</td>
                  <td>{row.status}</td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default EntitlementDisplayComponent;