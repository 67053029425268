const httpClient = require('../../services/httpClient');
const config = require('../../config/config.json');

export const passwordService = (function () {
  return {
    resetPassword: async (username, oldPassword, newPassword) => {
      try {
        const url = `${config[process.env.REACT_APP_ENVIRONMENT]['AUTH_URL']}`;
        const data = {
          method: 'RESET_PASSWORD',
          body: { username, newPassword, oldPassword }
        }
        let result = await httpClient.post(url, data);
        return result;
      }
      catch (e) {
        console.error(e);
        return e;
      }
    },
    setNewPassword: async (username, newPassword) => {
      try {
        const url = `${config[process.env.REACT_APP_ENVIRONMENT]['AUTH_URL']}`;
        const data = {
          method: 'SET_NEW_PASSWORD',
          body: { username, newPassword }
        }
        let result = await httpClient.post(url, data);
        return result;
      }
      catch (e) {
        console.error(e);
        return e;
      }
    }
  };
})();
