import request from 'request';
import header from './header';
import encrypt from './encrypt';

export function get(uri) {
  return new Promise((resolve, reject) => {
    request.get(uri, header.getOptions(), function (error, response, body) {
      if (error) {
        reject(error);
      } else {
        resolve(body);
      }
    });
  });
}

export async function post(uri, data) {
  const encrypted = await encrypt(data);
  // console.log(encrypted);
  const options = header.optionsWithJsonData(encrypted);
  return new Promise((resolve, reject) => {
    request.post(uri, options, function (error, response, body) {
      if (error) {
        reject(error);

      } else {
        if (401 === response.statusCode || 400 === response.statusCode) {
          reject(response);
        } else {
          resolve(body);
        }
      }
    });
  });
}

export function put(uri, data) {
  const options = header.optionsWithJsonData(data);
  return new Promise((resolve, reject) => {
    request.put(uri, options, function (error, response, body) {
      if (error) {
        reject(error);
      } else {
        resolve(body);
      }
    });
  });
}


export async function postLocal(uri, data) {
  const options = header.optionsWithJsonData(data);
  return new Promise((resolve, reject) => {
    request.post(uri, options, function (error, response, body) {
      if (error) {
        reject(error);

      } else {
        if (401 === response.statusCode || 400 === response.statusCode) {
          reject(response);
        } else {
          resolve(body);
        }
      }
    });
  });
}
