import React from 'react';
// import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import EntitlementDetailsEditor from './EntitlementDetailsEditor';
import { connect } from 'react-redux';

// import moment from 'moment';
// const formatDateVal = (dateVal) => dateVal ? moment.utc(dateVal).format('YYYY-MM-DD') : '';

const useStyles = makeStyles(theme => ({
  tableRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(0.8),
      width: 180,
    },
    '& th': {
      fontWeight: "bold",
      padding: "10px",
      border: "1px solid #80808036",
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: "white",
      boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.4)"
    },
    '& td': {
      zIndex: 1,
      // borderLeft: "1px solid #80808036",
      // borderRight: "1px solid #80808036"
      border: "1px solid #80808036",
      padding: "3px"
    },
    '& td.rowNum': {
      zIndex: 1,
      border: "1px solid #80808036",
      textAlign: "center"
    }
  },
}));

function FileContentsDisplayComponent({ data = {} }) {
  // console.log(`File content display!!`);
  const claimHeaderList = data.claimHeaderList;
  // console.log('to display: ', claimHeaderList);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState({
    row: {},
    index: -1
  });

  const handleOpen = (index, row) => {
    setOpen(true);
    setSelected({
      row, index
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowUpdate = (index, row) => {
    claimHeaderList[index] = row;
  }

  return (
    <div style={{ overflow: "auto", minHeight: "200px", maxHeight: "450px" }}>
      {open ? <EntitlementDetailsEditor open={open} onClose={handleClose} data={selected} onApply={handleRowUpdate} /> : ''}
      <table className={classes.tableRoot}>
        <thead>
          <tr>
            <th>Row Number</th>
            <th>Client Number</th>
            <th>Service Requested (Sr) Number</th>
            <th>Repair Envelope Number Or Invoice Number</th>
            <th>Store Number (Where Purchased)</th>
            <th>Terminal Number</th>
            <th>Date Of Purchase</th>
            <th>Transaction Number / Plan Number</th>
            <th>Failure Date</th>
            <th>Service Center Receive Date</th>
          </tr>
        </thead>
        <tbody>
          {claimHeaderList.map((row, index) =>
            <React.Fragment key={row.index}>
              <tr>
              <td className="rowNum"><button className="button is-link is-text is-inverted is-medium" onClick={() => handleOpen(index, row)}>{index + 1}</button></td>
                <td>{row.clientNumber}</td>
                <td>{row.srNumber}</td>
                <td>{row.repairEnvelopeNumber}</td>
                <td>{row.storePurchaseNumber}</td>
                <td>{row.terminalNumber}</td>
                <td>{row.purchaseDate}</td>
                <td>{row.transactionNumber}</td>
                <td>{row.failureDate}</td>
                <td>{row.scReceiveDate}</td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.entitlement.parsed
});

const FileContentsDisplay = connect(mapStateToProps)(FileContentsDisplayComponent);
export default FileContentsDisplay;